window.copy = function(e) {
  let code = e.closest('.highlight-wrap').querySelector('.rouge-code')
  window.getSelection().selectAllChildren(code)
  navigator.clipboard.writeText(code.innerText)
    .then(() => e.innerText = 'Copied')
}

window.generateSlug = function(s, t) {
  let source = s
  let target = t
  if (source.constructor === String) source = document.querySelector(source)
  if (target.constructor === String) target = document.querySelector(target)

  fetch(`/api/v1/generate_slug?name=${source.value}`, {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => response.json())
    .then(data => {
      target.value = data.slug
    })
    .catch(() => console.warn('Error occurred while generate slug'))
}

window.generateSlugForChildCategory = function(e) {
  let source = e.closest('.nested-fields').querySelector('.nested-fields-name')
  let target = e.closest('.nested-fields').querySelector('.nested-fields-slug')
  generateSlug(source, target)
}

window.autoGrow = function(element) {
  element.style.height='auto';
  element.style.height=element.scrollHeight+'px';
}

window.showFlashMessage = function(message, type='notice') {
  document.getElementById('flashMessage').classList.remove('flash-message-fade-out')
  if (type === 'alert') {
    document.querySelector('.flash-message-content:first-of-type').classList.add('flash-message-content--alert')
  } else {
    document.querySelector('.flash-message-content:first-of-type').classList.remove('flash-message-content--alert')
  }
  document.getElementsByClassName('flash-message-content')[0].innerText = message
  setTimeout(() => {
    document.getElementById('flashMessage').classList.add('flash-message-fade-out')
  }, 5000)
}
